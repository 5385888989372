export const CHANNEL_MODE = {
  chat: 'chat',
  assistant: 'assistant',
};

export const AI_SERVICES_CHANNELS = {
  whatsApp: 'whatsapp',
  email: 'email',
  website: 'website',
  facebook: 'facebook-page',
  instagram: 'instagram',
  bookingCom: 'booking.com',
  google: 'google',
  freshdeskEmail: 'freshdesk-email',
  freshchat: 'freshchat',
  zendeskEmail: 'zendesk-email',
  slack: 'slack',
  sms: 'sms',
  bookboost: 'bookboost',
};

export const CHANNELS_LABEL = {
  [AI_SERVICES_CHANNELS.whatsApp]: 'WhatsApp',
  [AI_SERVICES_CHANNELS.email]: 'Email',
  [AI_SERVICES_CHANNELS.website]: 'Website',
  [AI_SERVICES_CHANNELS.facebook]: 'Facebook',
  [AI_SERVICES_CHANNELS.instagram]: 'Instagram',
  [AI_SERVICES_CHANNELS.bookingCom]: 'Booking.com',
  [AI_SERVICES_CHANNELS.google]: 'Google',
  [AI_SERVICES_CHANNELS.freshdeskEmail]: 'Freshdesk',
  [AI_SERVICES_CHANNELS.freshchat]: 'Freshchat',
  [AI_SERVICES_CHANNELS.zendeskEmail]: 'Zendesk',
  [AI_SERVICES_CHANNELS.slack]: 'Slack',
  [AI_SERVICES_CHANNELS.sms]: 'SMS',
  [AI_SERVICES_CHANNELS.bookboost]: 'Bookboost',
};

export const AI_CHANNELS_MODAL_CONFIG = {
  [AI_SERVICES_CHANNELS.whatsApp]: { size: 800, disableClickOutside: true },
  [AI_SERVICES_CHANNELS.email]: { size: 800, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.website]: { size: 560, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.facebook]: { size: 800, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.instagram]: { size: 800, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.bookingCom]: { size: 800, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.google]: { size: 800, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.slack]: { size: 500, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.sms]: { size: 500, disableClickOutside: false },
  [AI_SERVICES_CHANNELS.freshchat]: { size: 560, disableClickOutside: false },
};

export const AI_SERVICES_CHANNELS_LIST = [
  {
    id: AI_SERVICES_CHANNELS.whatsApp,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.whatsApp],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.email,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.email],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.website,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.website],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.facebook,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.facebook],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.instagram,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.instagram],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.bookingCom,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.bookingCom],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.sms,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.sms],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.freshchat,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.freshchat],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.slack,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.slack],
    isMenu: true,
  },
  {
    id: AI_SERVICES_CHANNELS.google,
    label: CHANNELS_LABEL[AI_SERVICES_CHANNELS.google],
  },
];

export const AI_CHANNELS_CONFIG = {
  [AI_SERVICES_CHANNELS.whatsApp]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.website]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.facebook]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.instagram]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.google]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.slack]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.freshchat]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },

  [AI_SERVICES_CHANNELS.email]: {
    mode: CHANNEL_MODE.assistant,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.bookingCom]: {
    mode: CHANNEL_MODE.assistant,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.freshdeskEmail]: {
    mode: CHANNEL_MODE.assistant,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.zendeskEmail]: {
    mode: CHANNEL_MODE.assistant,
    isOvernightVisible: true,
  },
  [AI_SERVICES_CHANNELS.bookboost]: {
    mode: CHANNEL_MODE.chat,
    isOvernightVisible: true,
  },
};
