import { NavItemButton } from './NavItem';

let hasChatBubbleEle = true;

export default function HelpNavItem(props) {
  const onClickHandler = () => {
    if (hasChatBubbleEle) {
      document.querySelector('.akin__chat-popup__popup-container')?.remove();
      document.querySelector('#akin-chat-button')?.remove();
      hasChatBubbleEle = false;
    }

    const akinChatContainerEle = document.querySelector('#akin-chat-container');
    if (akinChatContainerEle) {
      window.cw('toggle-open');
    }
  };
  return <NavItemButton {...props} onClick={onClickHandler} />;
}
