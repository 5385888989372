import { Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';
import { useRouter } from 'next/router';

export const useStyles = createStyles((theme) => ({
  navItem: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0.8rem 0',
    lineHeight: '1.5',
    cursor: 'pointer',
    marginLeft: '0.8rem',
    marginTop: 5,
    marginBottom: 5,

    boxShadow: theme.other.boxShadow.cards,
    color: theme.white,

    '&:hover, &.selected': {
      backgroundColor: '#343536',
      color: theme.white,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    [theme.fn.smallerThan('xs')]: {
      backgroundColor: 'transparent',
      color: '#6D6B6B',
      boxShadow: 'none',
      '&:hover, &.selected': {
        backgroundColor: '#fafafa',
        color: '#3400CB',
      },
    },
  },
  label: {
    marginLeft: 10,
  },
  bar: {
    height: 4,
    width: '50%',
    borderRadius: 10,
    marginLeft: 10,
  },
}));

export default function SubMenuItem({
  title,
  description,
  isSelectedMenu,
  href,
  barColor,
  hidden = false,
}) {
  const { classes, cx } = useStyles();
  const router = useRouter();

  if (hidden) return null;

  const onChangeRoute = () => {
    router.push(href);
  };

  return (
    <div
      className={cx(classes.navItem, {
        selected: isSelectedMenu,
      })}
      onClick={() => onChangeRoute()}
      aria-hidden
    >
      {/* <Link href={href}> */}
      <div>
        <Text size={13} mb={5} weight={600} className={classes.label}>
          {title}
        </Text>
        <div className={classes.bar} style={{ backgroundColor: barColor }} />
        <Text size={10} mt={5} className={classes.label}>
          {description}
        </Text>
      </div>
      {/* </Link> */}
    </div>
  );
}
