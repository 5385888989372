import { getPlatformPageRoute } from '../../../../lib/router/utils';
import NavItemLink from './NavItem';
import NavSubMenuItem from './NavSubMenuItem';
import SubMenuItem from './SubMenuItem';

const BAR_COLORS = [
  '#74C098',
  '#9F7EFF',
  '#6D94F6',
  '#F1DA8F',
  '#FFAF64',
  '#D7BFE8',
];

export function processNavMenuList({ menuList, currentRoute, isMobileScreen }) {
  if (!currentRoute) return [];

  const navItemsList = menuList.map((menu) => {
    const isSelectedMenu =
      currentRoute === menu.route ||
      (menu.hasSubMenu && currentRoute.includes(menu.baseRoute));

    const href = menu.isExternalLink
      ? menu.route
      : getPlatformPageRoute(menu.route, { ...menu.query });

    const listItem = {
      component:
        isMobileScreen && menu.hasSubMenu ? NavSubMenuItem : NavItemLink,
      props: {
        key: `menu-${menu.id}`,
        title: menu.name,
        icon: menu.icon,
        href,
        isSelectedMenu,
        hidden: menu.hidden,
        ...(isMobileScreen &&
          menu.hasSubMenu && { subMenu: [...menu.subMenu] }),
      },
    };

    // If a custom menu component exists, override the default with it
    if (menu?.component) {
      listItem.component = menu.component;
    }

    return listItem;
  });

  return navItemsList;
}

export function processSubMenuList({ menuList, currentRoute }) {
  if (!currentRoute) return [];

  const navItemsList = menuList?.map((menu, index) => {
    const isSelectedMenu = currentRoute === menu.route;

    const href = menu.isExternalLink
      ? menu.route
      : getPlatformPageRoute(menu.route, { ...menu.query });

    const colorIndex = index % BAR_COLORS.length;

    return {
      component: SubMenuItem,
      props: {
        key: `menu-${menu.id}`,
        title: menu.name,
        href,
        isSelectedMenu,
        description: menu.description,
        barColor: BAR_COLORS[colorIndex],
        hidden: menu.hidden,
      },
    };
  });

  return navItemsList || [];
}
